// $card-spacer-y:                     1rem;
// $card-spacer-x:                     1rem;
// $card-title-spacer-y:               0.5rem;
// // $card-border-width:                 $border-width;
$card-border-color:                 rgba($black, .125);
// $card-border-color:                 var(--dcscc-gray);
// $card-border-radius:                1rem;
// // $card-box-shadow:                   null;
// // $card-inner-border-radius:          subtract($card-border-radius, $card-border-width);
// // $card-cap-padding-y:                $card-spacer-y * .5;
// // $card-cap-padding-x:                $card-spacer-x;
// // $card-cap-bg:                       transparent;
// // $card-cap-color:                    null;
// // $card-height:                       null;
// // $card-color:                        null;
// // $card-bg:                           transparent;
// // $card-img-overlay-padding:          $spacer;
// // $card-group-margin:                 $grid-gutter-width * .5;

// .card-footer {
//     .accordion-item:last-of-type {
//         border-bottom-right-radius: 1rem;
//         border-bottom-left-radius: 1rem;
//     }
//     .accordion-item:last-of-type .accordion-button.collapsed {
//         border-bottom-right-radius: calc(1rem - 1px);
//         border-bottom-left-radius: calc(1rem - 1px);
//     }
// }

.card-group {
    
    &.card-flowchart {
        
        @media (min-width: 576px) {
                
            .card {

                &:not(:first-child) {
                    padding-left: 1rem;
                }
            
                &:not(:last-child) {
                    
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        border: 1rem solid $card-border-color;
                        border-left-color: transparent;
                        border-bottom-color: transparent;
                        width: 0;
                        height: 0;
                        left: calc(100% + 1px);
                        top: 50%;
                        transform: translate(-50%,-50%) rotate(45deg);
                        transform-origin: 50% 50%;
                        z-index: 1;
                    }
                    
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        border: 1rem solid white;
                        border-left-color: transparent;
                        border-bottom-color: transparent;
                        width: 0;
                        height: 0;
                        left: 100%;
                        top: 50%;
                        transform: translate(-50%,-50%) rotate(45deg);
                        transform-origin: 50% 50%;
                        z-index: 2;
                    }
                }

                &.bg-primary {
                    border-color: var(--dcscc-light);
                    
                    &:before {
                        border-color: var(--dcscc-light);
                        border-left-color: transparent;
                        border-bottom-color: transparent;
                    }
                    &:after {
                        border-color: var(--dcscc-primary);
                        border-left-color: transparent;
                        border-bottom-color: transparent;
                    }
                }
            }
        }
    }

}



.card-label {
    margin-top: -4rem;
    text-align: center; 
    padding-left: 1rem; 
    padding-right: 1rem; 

    @media (min-width: 992px) {
        margin-left: 3rem; 
        margin-right: 3rem; 
    }

    span {
        --mark-color: white;
        --mark-skew: 0.25em;
        --mark-height: 1.5em;
        --mark-overlap: 0.5em;
        margin-inline: calc(var(--mark-overlap) * -1);
        padding-inline: var(--mark-overlap);
        background-color: transparent;
        background-image: linear-gradient( to bottom right, transparent 50%, var(--mark-color) 50% ), linear-gradient( var(--mark-color), var(--mark-color) ), linear-gradient( to top left, transparent 50%, var(--mark-color) 50% );
        /* background-size: var(--mark-skew) var(--mark-height), calc(100% - var(--mark-skew) * 2 + 1px) var(--mark-height), var(--mark-skew) var(--mark-height); */
        background-position: left center, center, right center;
        background-repeat: no-repeat;
        color: inherit;
        // -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
    }
}
