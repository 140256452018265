/**
 * typography
 */
.fw-bold.fw-bold {
    font-weight: 500 !important;
}
.fw-bolder.fw-bolder {
    font-weight: 700 !important;
}

/**
 * custom position utilities
 */
// sizing
.w-50 {
    width: 50% !important;
}
.top-25 {
    top: 25% !important;
}
@media (min-width: 992px) {

    // positioning
    .translate-lg-middle {
        -webkit-transform: translate(-50%, -50%) !important;
        transform: translate(-50%, -50%) !important;
    }

    .start-lg-50 {
        left: 50% !important;
    }
    .start-lg-100 {
        left: 100% !important;
    }

    .top-lg-25 {
        top: 25% !important;
    }
    .top-lg-50 {
        top: 50% !important;
    }
    .top-lg-100 {
        top: 100% !important;
    }

    
    .min-lg-vh-50 {
        min-height: 50vh !important;
    }
    .min-lg-vh-100 {
        min-height: 100vh !important;
    }

    // sizing
    .w-lg-50 {
        width: 50% !important;
    }
    .w-lg-100 {
        width: 100% !important;
    }
}







/**
 * vertical divider with OR
 */
 .vertical-divider {
    // position: absolute;
    display: table;
    text-align: center;
    
    height: 100%; 
    width: 100%;

    .center-element {
        position: relative;
        display: table-cell;
        vertical-align: middle;

        &:before, 
        &:after {
            position: absolute;
            content: "";
            width: 1px;
            left: 50%;
            border-left: 1px solid rgba(0,0,0,.125);
        }

        &:before {
            bottom: 50%;
            top: 0;
            margin-bottom: 20px;
        }
        &:after {
            top: 50%;
            bottom: 0;
            margin-top: 20px;
        }
    }

    @media (max-width: 992px) {
        position: relative;
        
        .center-element {

            &:before, 
            &:after {
                width: auto;
                height: 1px;
                left: auto;
                top: 50%;
                border-left: 0;
                border-top: 1px solid rgba(0,0,0,.125);
            }
            
            &:before {
                right: 50%;
                left: 0;
                margin-right: 20px;
                margin-top: 0;
            }
            &:after {
                left: 50%;
                right: 0;
                margin-left: 20px;
                margin-top: 0;
            }
        }
    }
}





/**
 * object fit cover or contain
 */
.object-fit-contain,
.object-fit-cover {
    width: 100%;
    min-width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.object-fit-contain {
    object-fit: contain!important;
}
.object-fit-cover {
    object-fit: cover!important;
}




/**
 * additional rounded corners
 */
.rounded-4 {
    border-radius: 1rem !important;
}
.rounded-5 {
    border-radius: 1.5rem !important;
}





/**
 * z-index utilities
 */
.z-index-n1 {
    z-index: -1 !important;
}
.z-index-0 {
    z-index: 0 !important;
}
.z-index-1 {
    z-index: 1 !important;
}
.z-index-10 {
    z-index: 10 !important;
}
.z-index-20 {
    z-index: 20 !important;
}





/**
 * patterns link chain
 */
.pattern-links-left {
    background-image: url("/dcscc/v2/images/pattern-links-left.svg");
    background-size: 700px;
    background-repeat: no-repeat;
    background-position: top left;
}
.pattern-links-bottom-left {
    background-image: url("/dcscc/v2/images/pattern-links-left.svg");
    background-size: 700px;
    background-repeat: no-repeat;
    background-position: 0% 350px;
}
.pattern-links-right {
    background-image: url("/dcscc/v2/images/pattern-links-right.svg");
    background-size: 700px;
    background-repeat: no-repeat;
    background-position: top right;
}
.pattern-links-right-best {
    background-image: url("/dcscc/v2/images/pattern-links-right-best.svg");
    background-size: 700px;
    background-repeat: no-repeat;
    background-position: top right;
}

.pattern-links-bottom-right {
    background-image: url("/dcscc/v2/images/pattern-links-right.svg");
    background-size: 700px;
    background-repeat: no-repeat;
    background-position: 100% 350px;
}






/**
 * list group numbered + circled numbers
 */
.list-group-numbered {

    &.list-group-numbered-circle {

        & > li {
            position: relative;
            padding-left: 3rem;

            &:before {
                content: counters(section, "");
                display: flex;
                width: 2em;
                height: 2em;
                margin-right: 1em;
                border-radius: 2em;
                background-color: rgba(var(--dcscc-dark-rgb), 0.1) !important;
                color: var(--dcscc-primary);
                text-align: center;
                font-weight: bold;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                top: -0.25em;
            }
        }
    }
}




.overflow-lg-unset.overflow-lg-unset.overflow-lg-unset {

    @media screen and (min-width: 992px){
        overflow: unset !important;
    }
}



.dropdown-menu-lg-end {
    
    @media screen and (min-width: 992px) {
        right: 0;
        left: auto;
    }
}



/** /
// aspect ratios
.ratio-1x1 {
    --dcscc-aspect-ratio: 100%;
    
    @media screen and (min-width: 576px) {
      --dcscc-aspect-ratio: 100%;
    }
}
/**/


.bg-blue.bg-blue {
    background-color: #BCD4E6;
}
