.alert.alert.alert {
    font-family: "Futura", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.alert-primary.alert-primary {
    background-color: $gray;
}
.alert-warning.alert-warning {
    background-color: #FFF981;
}


// $alert-padding-y:               $spacer;
// $alert-padding-x:               $spacer;
// $alert-margin-bottom:           1rem;
// $alert-border-radius:           $border-radius;
$alert-link-font-weight:        400;
// $alert-border-width:            $border-width;
// $alert-bg-scale:                -80%;
// $alert-border-scale:            -70%;
$alert-color-scale:             90%;
// $alert-dismissible-padding-r:   $alert-padding-x * 3; // 3x covers width of x plus default padding on either side