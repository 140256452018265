
$primary: #001489;
$secondary: #F48F43;
// $blue: #5AE5F8;
$cyan: #5AE5F8;
$red: #DC3545;
$yellow: #FEF208;
$orange: #F48F43;
$white:    #fff;
$gray-dark: #272727;
$dark: $gray-dark;
$gray: #BCD4E6;
$black: #000000;

$body-color: $gray-dark;
// $text-muted: #5D6D85;

:root {
    --dcscc-info-rgb: rgb(90, 229, 248);
    --dcscc-cyan: $cyan;
}
    
.bg-info {
    background-color: var(--dcscc-info-rgb);
}

// Links
//
// Style anchor elements.
// $link-color:                              $primary !default;
$link-shade-percentage:                   10%;
$link-color:                              shift-color($secondary, $link-shade-percentage);
$link-decoration:                         none;
$link-hover-color:                        $secondary;
$link-hover-decoration:                   underline;

.bg-dark {
    a:not(.btn) {
        color: $secondary;
    }
}

// scss-docs-start navbar-theme-variables
$navbar-dark-color:                 rgba($white, 1) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;