/** /
.navbar-dark {
    
    .navbar-nav {
        font-family: "Futura", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

        .nav-link {
            color: white;
        
            &:hover, 
            &:focus {
                color: var(--dcscc-secondary);
            }
        }
    }
}
/**/
#logo-main {

    @media screen and (min-width: 992px) {
        height: 50px;
    }
}

#main-nav {

    font-family: "Futura", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;

    .dropdown-toggle {

        @media screen and (min-width: 992px) {
            &:after {
                display: none;
            }
        }
    }
    
    .dropdown {

        &:hover {

            & > .dropdown-toggle {
                color: var(--dcscc-secondary);
            }

            & > .dropdown-menu {
                display: block;
            }
        } 

    }
    

    @media (min-width: 992px) {
        .navbar-nav .nav-link {
            padding-right: 0.8rem;
            padding-left: 0.8rem;
        }
    }
}

// .dropdown-toggle.dropdown-toggle.dropdown-toggle:after {
//     display: none;
// }




/** /
.dropdown-divider{
    width: 90%;
    margin: auto;
}
/**/
.dropdown-menu.dropdown-menu {
    border: none;
    // border-bottom: 2rem solid transparent;
    border-radius: 0;
    // background-color: rgba(var(--dcscc-primary-rgb), 0.5);
    // padding: 0.5rem 0.5rem;

    
    li{
        color: var(--dcscc-primary);
        // padding: 3px 1px;
        position: relative;
        
        &:hover > a {
            background-color: var(--dcscc-primary);
            color: var(--dcscc-secondary);
            text-decoration: none;
        }
    }
    
    // .dropdown-submenu {
    //     // display: none;
    //     // position: absolute;
    //     // left: 0;
    //     // top: 100%;
    //     background-color: $white;

    //     @media screen and (min-width: 992px) {
    //         display: none;
    //         position: absolute;
    //         left: 80%;
    //         top: -8px;
    //     }
    // }

    // .dropdown-submenu-left {
    //     right: 100%;
    //     left: auto;
    // }


    // @media screen and (min-width: 992px) {
    //     & > li:hover > .dropdown-submenu {
    //         display: block;
    //     }
    // }

}
/**/

// submenu of submenu
.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 80%;
    z-index: 1001;
}
.dropdown-submenu  {
    
    &:hover {

        & > .dropdown-menu {
            display: block;
        }
    }
}
.dropdown-level1.dropdown-level1 {
    background-color: #BCD4E6;
}
.dropdown-level2.dropdown-level2 {
    background-color: white;
}
.dropdown-level3.dropdown-level3 {
    background-color: #BCD4E6;
}

/**/
.dropdown-item.dropdown-item {
    // padding: 0.2rem 1rem;
    color: var(--dcscc-primary);
    font-family: "Acumin Variable Concept", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;

    // &:hover{
    //     background-color: var(--dcscc-primary);
    //     color: var(--dcscc-secondary);
    //     text-decoration: none;
    // }
}
/**/






#subheader {

    font-family: "Futura", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    
    top: 3.5rem;
    z-index: 1010;
    
    &.navbar-light .navbar-nav .nav-link {
        color: $gray-dark;
    }

    @media (min-width: 992px) {
        top: 5rem;

        .navbar-nav .nav-link {
            padding-right: 0.8rem;
            padding-left: 0.8rem;
        }
    }
}




$spacer:                      1rem;
$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$line-height-base:            1.5;
$font-size-lg:                $font-size-base * 1.25;
$nav-link-padding-y:                .5rem;
$nav-link-padding-x:                1rem;
$input-btn-focus-width:         .25rem;
$btn-focus-width:             $input-btn-focus-width;

$navbar-padding-y:                  $spacer * .5;
$navbar-padding-x:                  $spacer;

// $navbar-nav-link-padding-x:         .5rem;

// $navbar-brand-font-size:            $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
// $nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2;
// $navbar-brand-height:               $navbar-brand-font-size * $line-height-base;
// $navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5;
// $navbar-brand-margin-end:           1rem;

// $navbar-toggler-padding-y:          .25rem;
// $navbar-toggler-padding-x:          .75rem;
// $navbar-toggler-font-size:          $font-size-lg;
// $navbar-toggler-border-radius:      $btn-border-radius;
// $navbar-toggler-focus-width:        $btn-focus-width;
// $navbar-toggler-transition:         box-shadow .15s ease-in-out;

// $navbar-dark-color:                 rgba($white, .55);
$navbar-dark-color:                 $white;
// $navbar-dark-hover-color:           rgba($white, .75);
$navbar-dark-hover-color:           $secondary;
// $navbar-dark-active-color:          $white;
$navbar-dark-active-color:          $secondary;
// $navbar-dark-disabled-color:        rgba($white, .25);
// $navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
// $navbar-dark-toggler-border-color:  rgba($white, .1);

// $navbar-light-color:                rgba($black, .55);
// $navbar-light-hover-color:          rgba($black, .7);
// $navbar-light-active-color:         rgba($black, .9);
// $navbar-light-disabled-color:       rgba($black, .3);
// $navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
// $navbar-light-toggler-border-color: rgba($black, .1);

// $navbar-light-brand-color:                $navbar-light-active-color;
// $navbar-light-brand-hover-color:          $navbar-light-active-color;
// $navbar-dark-brand-color:                 $navbar-dark-active-color;
// $navbar-dark-brand-hover-color:           $navbar-dark-active-color;