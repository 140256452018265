:root {
    // --dcscc-body-font-size: 1.25rem !important;
    // --dcscc-subheadings-font-family: "Acumin Pro", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --dcscc-subheadings-font-family: "Acumin Variable Concept", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

$font-family-sans-serif: "Acumin Variable Concept", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$headings-font-family: "Acumin Variable Concept", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight: 500;

$font-weight-bolder:   500;

$lead-font-weight:     400;
.lead.lead {
    font-family: var(--dcscc-subheadings-font-family);
}

// ordered list decimal
ol[type="1"] {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
    
    & > li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }
    & > li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }
    & > li li {
        margin: 0;
    }
    //    & > li li:before {
    //      content: counters(item, ".") ". ";
    //    }
}