
@font-face {
    font-family: 'Futura';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local(''),
        url('/dcscc/v2/fonts/Futura-Medium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/dcscc/v2/fonts/Futura-Medium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Futura';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local(''),
        url('/dcscc/v2/fonts/Futura-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/dcscc/v2/fonts/Futura-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/** /
@font-face {
    font-family: 'Acumin Variable Concept';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local(''),
        // url('/dcscc/fonts/Gotham-Book.woff2') format('woff2'), 
        url('/dcscc/v2/fonts/AcuminVariableConcept.woff') format('woff'); 
}
/**/
@font-face {
    font-family: 'Acumin Variable Concept';
    src: url('/dcscc/v2/fonts/AcuminVariableConcept-WideExL.woff2') format('woff2'),
        url('/dcscc/v2/fonts/AcuminVariableConcept-WideExL.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Variable Concept';
    src: url('/dcscc/v2/fonts/AcuminVariableConcept-WdMdium.woff2') format('woff2'),
        url('/dcscc/v2/fonts/AcuminVariableConcept-WdMdium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: url('/dcscc/v2/fonts/AcuminPro-BoldItalic.woff2') format('woff2'),
        url('/dcscc/v2/fonts/AcuminPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: url('/dcscc/v2/fonts/AcuminPro-Italic.woff2') format('woff2'),
        url('/dcscc/v2/fonts/AcuminPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: url('/dcscc/v2/fonts/AcuminPro-Bold.woff2') format('woff2'),
        url('/dcscc/v2/fonts/AcuminPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Acumin Pro';
    src: url('/dcscc/v2/fonts/AcuminPro-Regular.woff2') format('woff2'),
        url('/dcscc/v2/fonts/AcuminPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

