.btn.btn.btn {
    font-family: "Acumin Variable Concept", "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 500;

    // &.text-light:hover {

    //     color: var(--dcscc-dark);
    // }
}

$btn-border-radius-sm: 1rem;
$btn-border-radius: 1rem;
$btn-border-radius-lg: 1rem;
$btn-padding-x-sm: 1.25rem;
$btn-padding-x: 1.5rem;
$btn-padding-x-lg: 2rem;
$btn-border-width: 2px;